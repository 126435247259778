#modal {
  font-family: 'Manrope', sans-serif; 
  z-index: 3000;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}
  
#modal-background {
  /* border: solid red 2px; */
  animation: fadeIn 0.2s;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.92), 5%, rgba(0, 0, 0, 0.85), 95%, rgb(0, 0, 0, 0.92));
  backdrop-filter: blur(10px);
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

#modal-content {
  /* border: red solid 2px; */
  animation: fadeIn 0.5s !important;
  display: flex;
  justify-content: center;
  width: 48%;
  max-width: 600px;
  height: 70vh;
  position: absolute;
  background-image: linear-gradient(to bottom right, rgb(255, 255, 255), rgb(243, 243, 243));
  border-radius: 15px;
  overflow: auto;
  scrollbar-gutter: stable both-edges;
}

#modal-content::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #afafafab;
  border-radius: 9px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #868686;
}
