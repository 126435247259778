.page {
    margin-top: 100px;
}

.spot-index {
    margin: 100px 40px;
}

.spot-container {
    /* border: 1px solid green; */
    margin: 30px;
    font-family: 'Manrope', sans-serif;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.spot-card {
    /* border: 1px solid hotpink; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 280px;
    margin: 10px;
    border-radius: 3px 3px 3px 3px;
    cursor: pointer;
}

/* .spot-card:hover {
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
} */
