#api-error {
    display: block;
    margin-top: 20px;
    margin-bottom: 3px;
    text-align: center;
    font-size: 14px;
}

input {
    display: flex;
    padding: 8px;
    margin-top: 8px;
    border-radius: 3px;
    border: 1px solid gray;
}

input:invalid[focused="true"] {
    border-radius: 3px;
    border: solid 1px rgb(255, 79, 138);
    background-color: rgba(255, 225, 225, 0.329) !important;
}

input:invalid[focused="true"]:focus {
    outline: 1px solid rgb(227, 28, 95);
}

input:invalid[focused="true"] ~ span {
    display: block;
}
