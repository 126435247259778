.CalendarDay__selected_span {
    background: rgba(255, 0, 106, 0.253);
    color: white;
    border: 1px solid rgb(255, 133, 204);
  }
  .CalendarDay__selected {
    background: rgb(255, 0, 98);
    color: white;
  }
  .CalendarDay__selected:hover {
    background: rgb(110, 0, 124);
    color: white;
  }
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: rgb(255, 210, 238);
  }

.DateInput_input {
    font-weight: 200;
    font-size: 19px;
    line-height: 24px;
    color: #484848;
    background-color: #fff;
    width: 80%;
    padding: 11px 11px 9px;
    margin: 0px;
    margin-right: 0px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid transparent;
    border-left: 0;
    border-radius: 0;
}

.DateRangePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #dbdbdb;
    width: fit-content;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
    border-top: 26px solid transparent;
    border-right: 33px solid rgb(255, 0, 98);;
    bottom: 0;
    right: 0;
}
