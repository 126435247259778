::-webkit-scrollbar {
    width: 0px;
}

.spot-page-index {
    font-family: 'Manrope', sans-serif;
    margin-top: 100px;
    margin-left: 10%;
    margin-right: 10%;
}

.spot-page-wrapper {
    /* border: solid 3px red; */
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

#spot-info {
    /* border: solid 3px red; */
    display: flex;
    flex-direction: column;
}

#spot-title {
    font-size: 30px;
    font-weight: 400;
    margin-top: 20px;
    margin-bottom: 0px;
}

.spot-page-subheader {
    display: flex;
    flex-direction: row;
    align-items: center;
}

div.spot-page-subheader > ul {
    margin: 0px;
    padding-left: 28px;
}

#host-status {
    font-size: 18px;
    font-weight: 300;
    margin-top: 0px;
    margin-right: 0px;
}

#spot-location {
    font-size: 18px;
    font-weight: 400;
    margin-top: 0px;
}

.spot-image-container {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: auto;
    height: auto;
    /* border: solid red 3px; */
}


.spot-first-image {
    box-sizing: border-box;
    height: auto;
    width: 50%;
    object-fit: contain;
    /* border: solid blue 3px; */
}

.spot-image-inside {
    box-sizing: border-box;
    border-radius: 15px 0px 0px 15px;
    height: 100%;
    width: 100%;
    padding: 3px;
}

.spot-secondary-images-container {
    display: flex;
    align-content: space-around;
    flex-wrap: wrap;
    height: 100%;
    width: 50%;
    /* border: green solid 3px; */
}

.spot-secondary-images-container > img {
    box-sizing: border-box;
    width: 50%;
    padding: 3PX;
}

.spot-secondary-images-container > img:nth-child(2) {
    border-radius: 0px 15px 0px 0px;
}
.spot-secondary-images-container > img:nth-child(4) {
    border-radius: 0px 0px 15px 0px;
}


.spot-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}

.spot-titles {
    width: 90%;
    border-bottom: solid rgb(235, 235, 235) 2px;
}

.spot-titles > h1 {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-top: 20px;
}

.spot-titles > h3 {
    margin-top: 0px;
    font-size: 18px;
    font-weight: 400;
}

.spot-details > h4 {
    word-break:break-all;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
}

#review-section {
    width: 100%;
}

#review-section > h1 {
    font-size: 30px;
    font-weight: 400;
    margin-top: 20px;
    padding-bottom: 0px;
    width: 100%;
    justify-content: center;
    /* border-bottom: solid rgb(235, 235, 235) 2px; */
}

.spot-reviews {
    border: solid rgba(196, 196, 196, 0.507) 1px;
    border-radius: 4px;
    padding-top: 0px;
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image: linear-gradient(to bottom, rgb(255, 255, 255), 70%, rgb(253, 253, 253));
}

div.fa-solid.fa-star.fa-xs {
    margin-bottom: 0px;
    line-height: 2em;
    margin-left: 10px;
    margin-right: 3px;
}

.spot-reviews > div {
    /* border: solid rgb(255, 35, 35) 2px; */
    width: 100%;
}

.review-star {
    margin-right: 20px;
}

#star-spacing {
    margin-left: 90px;
}

#user-reviews-wrapper {
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    display: flex;
    border-bottom: solid rgb(235, 235, 235) 2px;
    background-image: linear-gradient(to bottom, rgb(244, 244, 244), rgb(244, 244, 244));
}

#user-reviews-wrapper:hover {
    background-image: linear-gradient(180deg, rgb(228, 228, 228), 0%, rgb(235, 235, 235), 80%, rgb(228, 228, 228));
}

#user-reviews-wrapper:last-child {
    border-bottom: none !important
}

#user-reviews {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.review-content {
    display: flex;
}

#username {
    margin-right: 20px;
}

.spot-review-form {
    /* border: red solid 2px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.spot-review-form > form {
    margin-top: 0px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
}

.spot-review-form > form > input{
    width: 100%;
    margin-bottom: 30px;
}

#review-form-button {
    margin-top: 30px;
    margin-bottom: 0px;
    text-align: center;
}

.review-delete-button {
    font-family: 'Manrope', sans-serif;
    color: white;
    width: 70px;
    background-color: rgb(63, 63, 63);
    border: none;
    border-radius: 10px;
    margin-right: 20px;
}

#delete-button {
    padding-left: 11px;
    padding-right: 10px;
}

.review-delete-button:hover {
    background-color: rgb(227, 28, 95);
    cursor: pointer;
}

/* #submit-review-button {
    justify-content: center !important;
    width: 100%
} */

.btn {
    /* margin-top: 0px; */
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 30px;
    background-color: rgb(63, 63, 63);
    color: white;
    font-size: 13pt;
    position: relative;
    overflow: hidden;
    width: 100%;
    justify-content: center;
    /* cursor: pointer; */

    --background-color: rgb(227, 28, 95);
    --border-size: 2px;
    --accent-color: rgb(94, 94, 94);
}

.btn.btn-review {
    z-index: 1;
    margin-bottom: 10px;
}

.btn.btn-review:hover {
    background-color: rgb(227, 28, 95);
}

.btn.btn-review::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-color);
    z-index: -1;
    border: var(--border-size) solid var(--background-color);
    transition: transform 150ms ease-in-out;
    transform: scaleX(0);
    transform-origin: left;
}

.btn.btn-review:hover::before {
    transform: scaleX(.08);
}

#review-form-error {
    margin-bottom: 5px;
    margin-top: 0px;
    font-size: 14px;
    color: rgb(227, 28, 95);;
}

#submit-review-button {
    margin-top: 0px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 30px;
    background-color: rgb(63, 63, 63);
    color: white;
    font-size: 13pt;
    position: relative;
    overflow: hidden;
    width: 100%;
    justify-content: center;
}

#submit-review-button:hover {
    background-color: rgb(227, 28, 95);
    cursor: pointer;
}
