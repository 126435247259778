.navbar {
    font-family: 'Manrope', sans-serif;
    /* border: solid green 3px; */
    position: fixed;
    background-color: white;
    z-index: 200;
    top: 0px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.267);
}

#logo-text {
    font-size: 30px;
    font-weight: bold;
    color: rgb(227, 28, 95);
}

#logo-link {
    text-decoration: none;
}

div.nav-elements {
    /* border: solid red 3px; */
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 8px;
}

svg.nav-elements {
    fill: rgb(227, 28, 95);
}

#profile-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 15px 5px 15px;
    border-radius: 20px;
    border: 2px solid rgba(0, 0, 0, 0.267);
}

#profile-button > img {
    width: 20px;
    margin-right: 7px;
}

#profile-button:hover {
    box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.3);
}

.profile-dropdown {
    box-sizing: border-box;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    text-align: center;
    width: 200px;
    top: 50px;
    right: 50px;
    border-radius: 10px;
    height: 250px;
    background-color: white;
    margin: 0px;
    padding: 0px;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.267);
}

.profile-dropdown-user-info {
    display: flex;
    flex-direction: column;
}

ul.profile-dropdown {
    list-style-type: none;
}

ul.profile-dropdown > li {
    box-sizing: border-box;
    width: 100%;
}

ul.profile-dropdown>li:nth-child(-n - 2):hover {
    pointer-events: none !important
}

ul.profile-dropdown>li:hover {
    background-color: rgb(240, 240, 240);
    cursor: pointer;
}

.profile-dropdown-buttons {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border: none;
}
