.signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    padding: 0;
    margin: auto;
}

.formInput {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    font-size: 12px;
}

form {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* margin: 5% 0%; */
    margin-left: 0px;
    max-width: 400px;
}

.btn {
    /* margin-top: 5px; */
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-bottom: 30px;
    background-color: rgb(63, 63, 63);
    color: white;
    font-size: 13pt;
    position: relative;
    overflow: hidden;
    /* cursor: pointer; */

    --background-color: rgb(227, 28, 95);
    --border-size: 2px;
    --accent-color: rgb(94, 94, 94);
}

.btn.btn-signup {
    z-index: 1;
}

.btn.btn-signup::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--background-color);
    z-index: -1;
    border: var(--border-size) solid var(--background-color);
    transition: transform 150ms ease-in-out;
    transform: scaleX(0);
    transform-origin: left;
}

.btn.btn-signup:hover::before {
    transform: scaleX(.08);
}

form > h1 {
    text-align: center;
    color: rgb(227, 28, 95);
}

.signup span {
    color: rgb(227, 28, 95);
    font-size: 10pt;
    display: none;
    margin-top: 0px;
}
