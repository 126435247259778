
.spot-info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 3px;
}

.spot-info {
    /* border: 1px solid red; */
    overflow: auto;
    width: 63%;
}

.spot-info > p {
    /* border: 1px solid pink; */
    margin: 0px;
    color: grey;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.spot-page-wrapper {
    /* border: solid red 3px; */
    width: auto;
    margin: 0% 10%;
}

.spot-image {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 15px;
    margin-bottom: 5px;
}

.spot-rating-container {
    /* border: solid red 3px; */
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 37%;
}

.spot-rating {
    /* border: green solid 2px; */
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: auto;
    align-items: center;
    margin-top: 0px;
}

.spot-rating > p {
    /* border: solid red 3px; */
    margin-left: 4px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.price-container {
    /* border: green solid 2px; */
    display: flex;
    flex-direction: row;
}
.price-container > p {
    /* border: solid 3px blue; */
    margin-top: 8px;
    margin-bottom: 0px;
}
.price-container > p:first-child {
    margin-right: 5px;
}

.spot-card-button-wrapper {
    /* border: solid red 1px; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: rgb(59, 59, 59);
    color: white;
    border-radius: 0px 0px 13px 13px;
    margin-top: 20px;

}

.spot-card-title {
    color: black !important;
}

.spot-card-buttons {
    width: 100%;
    text-align: center;
}

.spot-card-buttons:first-child{
    border-radius: 0px 0px 0px 13px;
}

.spot-card-buttons:last-child{
    border-radius: 0px 0px 13px 0px;
}

.spot-card-buttons:hover {
    background-color: rgb(227, 28, 95);
    cursor: pointer;
}
